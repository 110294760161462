<template>
  <v-container fluid class="pa-0 ma-0">

    <v-toolbar
      dense
      flat
      color="grey lighten-2"
      class="ma-0 pa-0"
    >
      <div class="text-caption text-lg-left blue-grey--text">
        <v-icon small color="primary" class="mb-1">info</v-icon>
        <span class="text-body-2 primary--text ml-1">카테고리의 변경,삭제,합체는  연계 정보의 안정성 확보를 위해 일과시간 이외에 하기를 권장합니다.</span>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn text icon>
        <v-icon small>apps</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-card
      tile
      :elevation="0"
    >
      <v-row
        align="center"
        justify="center"
        no-gutters
        class="ma-0 pa-0"
      >
        <v-col cols="12">

          <!-- content area -->
          <v-row
            justify="space-between"
            class="ma-0 pa-0"
          >
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? '4' : '2'"
            >
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0 overflow-y-auto"
                max-width="300"
                min-width="120"
              >
                <v-list dense shaped>
                  <template v-for="(item, index) in items">
                    <v-divider
                      v-if="item.divider"
                      :key="index"
                      :inset="item.inset"
                    ></v-divider>

                    <v-list-item
                      v-else
                      :key="item.title"
                      :to="item.to"
                      color="primary"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? '7' : ''"
            >
              <!-- 여기에 컴포넌트를 매칭시킬 수 있어야 한다 -->
              <router-view></router-view>
            </v-col>
          </v-row>
          <!--/content area -->

        </v-col>
      </v-row>
    </v-card>

  </v-container>
</template>

<script>

export default {
  components: {
  },

  data: () => ({
    item: 1,
    items: [
      { title: '관리그룹', to: { path: '/admin/cateManage/cate01' } },
      { divider: true, inset: false },
      { title: '소송분야', to: { path: '/admin/cateManage/cate02/2' } },
      { title: '자문분야', to: { path: '/admin/cateManage/cate02/3' } },
      { title: '자문유형', to: { path: '/admin/cateManage/cate03/4' } },
      { title: '종국결과', to: { path: '/admin/cateManage/cate05/5/6' } },
      { title: '사건관리상태', to: { path: '/admin/cateManage/cate08' } }, // [2021.2.26] 신설
      // { title: '경유증표', to: { path: '/admin/cateManage/cate03/7' } }, // [2021.2.26] 주석처리
      { divider: true, inset: false },
      { title: '주체유형', to: { path: '/admin/cateManage/cate04' } },
      { divider: true, inset: false },
      { title: '업무유형', to: { path: '/admin/cateManage/cate07' } }, // [2019.11.26] 07로 뺀다
      // { title: '문서유형', to: { path: '/admin/cateManage/cate03/10' } }, // [2019.11.8]삭제
      { title: '업무관리상태', to: { path: '/admin/cateManage/cate09' } }, // [2021.3.3] 신설
      { title: '수금항목', to: { path: '/admin/cateManage/cate03/11' } },
      { title: '수금관리상태', to: { path: '/admin/cateManage/cate10' } }, // [2021.3.3] 신설
      { title: '비용항목', to: { path: '/admin/cateManage/cate03/12' } },
      { divider: true, inset: false },
      { title: '게시판구분', to: { path: '/admin/cateManage/cate03/13' } },
      { divider: true, inset: false },
      { title: '첨부파일구분', to: { path: '/admin/cateManage/cate11' } }, // [2021.3.3] 신설
      { divider: true, inset: false },
      { title: '직급구분', to: { path: '/admin/cateManage/cate05/14/15' } },
      { title: '발령구분', to: { path: '/admin/cateManage/cate06' } },
      { title: '근태유형', to: { path: '/admin/cateManage/cate03/17' } },
      { title: '인사평가', to: { path: '/admin/cateManage/cate05/18/19' } }
      // { title: '나의현재상태', to: { path: '/admin/cateManage/cate03/20' } } // [2021.2.26] 주석처리
    ]
  }),

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },

    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },

    redirect (to = '') {
      this.$router.push(to)
    }
  }
}
</script>
